import ReactPaginate from "react-paginate";
import FileIcon from "components/FileIcon";
import { useEffect, useState } from "react";
import { BEARER_TOKEN } from "../../../src/store/config";
import styles from "../AccountPage/style.module.css";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const PageInformation = () => {
  //const [allOrders, setAllOrders] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Word");
  const [fileTypes, setFileTypes] = useState<any[]>([]);

  const ordersPerPage = 10;

  const fetchDetailPurchase = async (pageNumber: number, fileTypeId: number | null) => {
    try {
      // console.log("Enviando solicitud con:", {
      //   number: pageNumber,
      //   size: ordersPerPage,
      //   typeFileId: fileTypeId,
      //   description: ""
      // });
  
      const response = await axios.post(
        "https://api.yosoymitosis.com/v1/InformationFiles/GetDocumentsForUser",
        {
          number: pageNumber,
          size: ordersPerPage,
          typeFileId: fileTypeId,
          description: ""
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${BEARER_TOKEN}`
          }
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        // setAllOrders(data.data.informationFilesForUsers);
        setOrders(data.data.informationFilesForUsers);
        setTotalPages(data.data.totalPages);
      } else {
        console.error("Error fetching orders:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchFileTypes = async () => {
    try {
      const response = await axios.get("https://api.yosoymitosis.com/v1/InformationFiles/GetListFileType", {
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN}`
        }
      });

      if (response.status === 200) {
        setFileTypes(response.data.data);
      } else {
        console.error("Error fetching file types:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  useEffect(() => {
    fetchDetailPurchase(currentPage, 1);
    fetchFileTypes();
  }, [currentPage]);

  const paginateNew = (data: any) => {
    setCurrentPage(data.selected + 1);
  };

  const showNextButton = currentPage < totalPages;
  const showPrevButton = currentPage > 1;

  const handleTabClick = (tabName: string, idFileType: number) => {
    setActiveTab(tabName);
    setCurrentPage(1);
    
    fetchDetailPurchase(currentPage, idFileType);
  };

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Información</title>
      </Helmet>
      <div className="flex flex-col justify-center border-b border-[#d1d5db] pt-6">
        <h2 className="text-2xl sm:text-3xl text-center font-semibold mb-5">
          Información
        </h2>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex justify-center sm:justify-start flex-grow-0 flex-shrink-0 flex-[260px] overflow-auto h-[500px] pt-2 pr-4 pb-8 pl-4 border-r border-gray-300 bg-white">
          <section className="text-start w-full">
            <header className="py-2 px-0 mb-2">
              <i className="las la-folder mr-2"></i>
              <b className="font-medium">Espacio de trabajo</b>
            </header>
            <ul className="space-y-3">
              {fileTypes.map((type) => (
                <li key={type.fileTypeId} className="cursor-pointer" onClick={() => handleTabClick(type.description, type.fileTypeId)}>
                  <header
                    className={`flex items-center justify-between px-6 py-2 ${
                      activeTab === type.description ? "bg-[#cfb9e3]" : ""
                    }`}
                  >
                    <label className="cursor-pointer">
                      <b className="text-blue-900 font-medium w-full inline-block max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis align-top">
                        {type.description}
                      </b>
                    </label>
                  </header>
                </li>
              ))}
            </ul>
          </section>
        </div>
        <div className="bg-[#f6f7fa] overflow-auto flex-row flex-wrap justify-start items-start p-2 flex-shrink-[1] flex-grow-[1] basis-full flex relative h-auto">
          <div className="w-fit h-auto sm:px-8 pt-6 pb-4 sm:pb-0 flex flex-col mx-auto sm:inline-block">
            {orders.length > 0 ? (
              orders.map((item, index) => (
                <div
                  key={index}
                  className="flex-auto max-w-[570px] h-auto px-0 py-0 mx-4 my-6 border border-gray-300 bg-white cursor-pointer select-none relative inline-block w-full transition-all duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1 mb-2"
                  style={{ direction: "ltr" }}
                >
                  <div className="flex text-[#9DA1AA] absolute top-auto left-auto bottom-1.5 right-1.5 text-base">
                    <i
                      className="las la-file-download transition-transform duration-300 ease-in-out transform hover:scale-110 text-3xl invert-[50%] hover:invert"
                      onClick={() => {
                        const fileUrl = item.fileUrl;
                        const fileName = item.fileName;

                        window.open(fileUrl, "_blank");

                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.download = fileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    ></i>
                  </div>
                  <div className="w-20 float-left">
                    <div className="bg-[#9333ea] border-b border-[#9DA1AA] h-full min-h-[80px] flex justify-center items-center">
                      <FileIcon fileType={item.fileName.split(".").pop()} width={48} />
                    </div>
                  </div>
                  <div className="pl-[88px]">
                    <div className="p-2 flex flex-col min-h-[80px] h-full justify-between">
                      <div className="w-full mb-0 text-ellipsis whitespace-nowrap overflow-hidden text-[#091124] text-[13px] font-medium break-words leading-[1.2]">
                        <span>{item.fileName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No se encontraron archivos.</p>
            )}
          </div>
          <div className="flex justify-center w-full mt-10">
            <ReactPaginate
              previousLabel={
                showPrevButton ? (
                  <span className="w-10 h-10 flex items-center justify-center transition-colors bg-[#a59dac] hover:bg-[#625c67] rounded-md text-white">
                    <i className="las la-angle-left"></i>
                  </span>
                ) : null
              }
              nextLabel={
                showNextButton ? (
                  <span className="w-10 h-10 flex items-center justify-center transition-colors bg-[#a59dac] hover:bg-[#625c67] rounded-md text-white">
                    <i className="las la-angle-right"></i>
                  </span>
                ) : null
              }
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={paginateNew}
              containerClassName="flex items-center justify-center mb-4 gap-2 mt-4 sm:mt-0"
              pageClassName={`custom-link block border border-none hover:bg-[#a764e0] hover:${styles.shadowInner} hover:text-white transition-colors w-10 h-10 flex items-center justify-center rounded-md`}
              activeClassName={`bg-[#a764e0] text-white transition-colors transition duration-300 ${styles.shadowInner}`}
            ></ReactPaginate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageInformation;
