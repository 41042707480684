import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useCounterStore } from "store/auth";
import Swal from "sweetalert2";

interface SessionMonitorProps {
  checkInterval: number;
}

const SessionMonitor: React.FC<SessionMonitorProps> = ({ checkInterval }) => {
  const [isActive, setIsActive] = useState(true);
  const timeoutRef = useRef<number | null>(null);

  const handleLogout = useCounterStore((state) => state.handleLogout);
  const isLoggedIn = useCounterStore((state) => state.isLoggedIn);

  // 1. Función para almacenar la última actividad del usuario en localStorage (REINICIAR EL TEMPORIZADOR DE 5 MINUTOS)
  const handleUserActivity = () => {
    localStorage.setItem("lastActivityTime", Date.now().toString());

    // Reinicia el temporizador de inactividad
    if (timeoutRef.current) {//valida si existe un temporizador activo. timeoutRef.current guarda un ID del temporizador establecido previamente.
      clearTimeout(timeoutRef.current);// Si hay un temporizador activo, lo cancela, es decir si el usuario hace clic y enseguida hace otro evento como mover el puntero, se debe cancelar el temporizador anterior y se vuelve a ejecutar uno nuevo de 5 minutos en este caso. Esto garantiza que solo haya un temporizador activo en cualquier momento.
    }

    //Se establece un nuevo temporizador de 5 minutos con setTimeout
    timeoutRef.current = window.setTimeout(() => { //window.setTimeout es una función que ejecuta un bloque de código o una función después de un tiempo determinado, en este caso 5 minutos (5 * 60 * 1000)
      setIsActive(false);// Si no hay actividad en 5 minutos, el estado isActive será false o sea que el usuario no está activo
    }, 5 * 60 * 1000);// 5 * 60 * 1000 = 5 minutos
  };

  // 2. Comprobar si ha pasado el tiempo de inactividad al cargar la página
  const checkInactivityOnLoad = () => {
    const lastActivityTime = localStorage.getItem("lastActivityTime");
    if (lastActivityTime) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - parseInt(lastActivityTime, 10);

      if (timeElapsed > 5 * 60 * 1000) {
        setIsActive(false); // Usuario inactivo si pasaron más de 5 minutos
      }
    }
  };

  // // useEffect para manejar actividad del usuario y cerrar sesión tras 5 min de inactividad
  // useEffect(() => {
  //   if (!isLoggedIn) return;//Si no está logueado que no ejecute lo que sigue acontinuación.

  //   // Eventos que detectan la actividad del usuario
  //   const events = ["mousemove", "mousedown", "click", "scroll", "keypress"];
  //   events.forEach(event => window.addEventListener(event, handleUserActivity));

  //   // Inicializa el timeout de inactividad al montar el componente
  //   timeoutRef.current = window.setTimeout(() => {
  //     setIsActive(false); // Si no hay actividad en 5 minutos, inactividad
  //   }, 5 * 60 * 1000);

  //   // Limpiar los eventos al desmontar el componente
  //   return () => {
  //     events.forEach(event => window.removeEventListener(event, handleUserActivity));
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }
  //   };
  // }, [isLoggedIn]);

  // 3. Cierre de sesión si el usuario está inactivo: useEffect para cerrar sesión cuando se detecta inactividad o se excede el tiempo de la sesión
  useEffect(() => {
    if ((!isActive && isLoggedIn)) {
      Swal.fire({
        icon: "warning",
        title: "Sesión expirada por inactividad.",
        text: "Serás redirigido a la página de inicio de sesión.",
      }).then(() => {
        handleLogout();
      });
    }
  }, [isActive, isLoggedIn]);

  // Efecto para manejar la actividad del usuario
  useEffect(() => {
    if (!isLoggedIn) return;

    // 1. Almacena la última actividad
    // handleUserActivity(); // no va porque cuando se actualiza la pagina se vuelve a registrar el lastActivityTime del LS

    // 2. Comprueba inactividad al cargar la página
    checkInactivityOnLoad();

    // Eventos que detectan la actividad del usuario
    const events = ["mousemove", "mousedown", "click", "scroll", "keypress"];
    const handleUserActivityWrapper = () => {
      handleUserActivity(); // Solo llamamos a esta función en los eventos
    };
    events.forEach((event) =>
      window.addEventListener(event, handleUserActivityWrapper)
    );

    // Limpiar eventos al desmontar el componente
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivityWrapper)//removemos el listener respectivo ya que son varios, y de no removerlo ocasionaria multiples instancias de listeners activos, lo que puede llevar a fuga de memoria o duplicado en alerta de inactividad.
      );
      // Reinicia el temporizador de inactividad
      if (timeoutRef.current) {//valida si existe un temporizador activo. timeoutRef.current guarda un ID del temporizador establecido previamente.
        clearTimeout(timeoutRef.current);// Si hay un temporizador activo, lo cancela, es decir si el usuario hace clic y enseguida hace otro evento como mover el puntero, se debe cancelar el temporizador anterior y se vuelve a ejecutar uno nuevo de 5 minutos en este caso. Esto garantiza que solo haya un temporizador activo en cualquier momento.
      }
    };
  }, [isLoggedIn]);

  /* ------------------ Para el cierre de sesión si ya pasó 1 hora de inicio de sesión  -------------------*/
  // Verificación de la duración de la sesión (1 hora desde inicio de sesión)
  const checkSessionTimeout = () => {
    const loginTimeString: string | null = localStorage.getItem('loginTime');
    if (loginTimeString) {
      const loginTime: number = parseInt(loginTimeString, 10); // Convertir a número lo obtenido del localStorage
      const currentTime: number = new Date().getTime(); // Hora actual en milisegundos
      const elapsedTime: number = currentTime - loginTime; // Tiempo transcurrido en milisegundos
      const oneHour: number = 60 * 60 * 1000; // 1 hora en milisegundos

      // Si ha pasado una hora o más desde el inicio de sesión, cerrar sesión
      if (elapsedTime >= oneHour - 5 * 60 * 1000) {// Si faltan 5 minutos para cerrar la sesión
        Swal.fire({
          icon: "warning",
          title: "Tu sesión está a punto de expirar.",
          text: "Haz clic en 'Extender' para mantener la sesión activa.",
          showCancelButton: true,
          confirmButtonText: 'Extender',
        }).then((result) => {
          if (result.isConfirmed) {
            // Extender la sesión
            localStorage.setItem('loginTime', new Date().getTime().toString());
          } else {
            handleLogout();
          }
        });
      }
    }
  };


  // Verificación de la duración de la sesión cada 5 minutos y al abrir la página
  useEffect(() => {
    // Verificar inmediatamente al abrir la página
    checkSessionTimeout();

    // Ejecutar la validación cada 5 minutos
    const intervalId = setInterval(checkSessionTimeout, 5 * 60 * 1000); // 5 minutos

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  return null; // No necesita renderizar nada, solo actúa en el fondo
};

export default SessionMonitor;
